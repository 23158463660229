<!-- eslint-disable -->
<template>
  <div class="information">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan">
            <img src="../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{path:'/index' }">预警信息</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div class="information-box">
      <div v-loading="loading" class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div class="main-top-center">
            <div class="main-top-center-box">
              <div>已预警</div>
              <p v-html="textData.title ? textData.title : ''" class="center-title"></p>
            </div>
            <div class="caozuo-center">
              <div :class="['type',textData.tonalState == 2? 'negative': textData.tonalState == 1? 'neutral': 'obverse',]">
                {{textData.tonalState == 2? "负面": textData.tonalState == 0? "正面": "中性"}}
              </div>
              <div v-if="textData.mediaLink != null" class="type website">{{ textData.mediaLink }}</div>
              <span class="original original-color" @click="routerOriginal(textData.website ? textData.website : '无')">
                <span>
                  <img src="../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </span>
              <div class="center-timer">
                发布时间:
                <span>
                  {{textData.releaseTime ? textData.releaseTime : "无"}}
                </span>
              </div>
            </div>
            <div class="operation-box">
              <div class="operation-copy my-caozuo" @click="copy(textData)">
                <span>
                  <span>
                    <img src="../assets/img/views/fuzhi.png" alt />
                  </span>
                </span>
                <span>复制全部</span>
              </div>
              <div @click="quickCopy(textData)" class="my-caozuo">
                <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                复制链接和标题
              </div>
            </div>
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 24px 210px 0 210px">
          <!-- 中间青色位置 -->
          <div class="main-center">
            <div class="main-center-left">
              <div class="left-source">
                媒体性质:
                <span>
                  {{(textData.mediaLink?textData.mediaLink:"其它")+"/"+(textData.mediaAttribute?textData.mediaAttribute:"其它")+"/"+(textData.mediaLevel?textData.mediaLevel:"其它")}}
                </span>
                <span class="my-caozuo" @click="myCopy([textData.mediaLink,textData.mediaAttribute,textData.mediaLevel,],4)">
                  <img src="../assets/img/views/hfz.png" alt /> 
                </span>
              </div>
              <div class="left-source">
                来源/作者:
                {{(textData.source ? textData.source : "无") +"/" +(textData.author ? textData.author : "无")}}
                <span class="my-caozuo" @click="myCopy('',1)">
                  <img src="../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="event-box">
                <span class="eventing">事件分类:</span>
                <span>
                  {{textData.eventList?textData.eventList[0]:"无"}}
                </span>
                <el-tooltip
                  v-if="textData.eventClass && textData.eventList.length >= 2"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.eventClass"></div>
                  <span class="gengduo-link">(更多)</span>
                </el-tooltip>
                <span class="my-caozuo" @click="myCopy(textData.eventClass, 2)">
                  <img src="../assets/img/views/hfz.png" alt />
                </span>
              </div>
              <div class="left-language">
                关键字:
                <span v-if="textData.keyword">
                  <span
                    style="margin-left:0px"
                    v-for="(ite,indx) in textData.keywordList"
                    :class="[ite!=textData.keywordList[textData.keywordList.length-1]?'styleMargin':'',]"
                    :key="indx"
                  >
                    {{ ite }}
                  </span>
                </span>
                <span v-else>无</span>
                <el-tooltip
                  v-if="textData.keyword&&textData.keywordArry && textData.keywordArry.length > 10"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.keyword"></div>
                  <span class="gengduo-link">(更多)</span>
                </el-tooltip>
                <span class="my-caozuo" @click="myCopy(textData.keyword, 6)">
                  <img src="../assets/img/views/hfz.png" alt />
                </span>
              </div>
            </div>
            <div class="main-center-right">
              <div class="center-right-img">
                <el-image style="width: 100px; height: 100px" :src="textData.fastPic" :preview-src-list="srcList">
                  <div slot="error" class="image-slot">
                    <img src="../assets/img/views/no.jpg" alt />
                  </div>
                </el-image>
              </div>
            </div>
          </div>
          <p v-html="item ? item : ''" class="main-text" v-for="(item, index) in textData.contentList" :key="index"></p>
          <div v-if="textData.videoPath" class="demo-image__preview" style="margin-top: 16px">
            <el-image
              style="max-width:600px;max-height:400px"
              v-if="textData.videoPath.type.slice(0,5) == 'image'"
              :src="textData.videoPath.url"
              :preview-src-list="srcLists"
            ></el-image>
            <div v-if="textData.videoPath.type.slice(0,5) == 'video'">
              <video width="100%" height="300px" :src="textData.videoPath.url" controls="controls"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { wxDataQuery } from "../api/index/index.js";

export default {
  data() {
    return {
      srcLists: [],
      srcList: [],
      teamId: "",
      dataId: "",
      textData: {},
      loading: true,
    };
  },
  components: {},
  methods: {
    // 查看原文
    routerOriginal(web) {
      window.open(web);
    },
    copy(msg) {
      this.$publicFun.copyFun(msg.copy);
    },
    //复制链接与标题
    quickCopy(msg){
      let str = "";
      str = "标题："+msg.title+"\n"+"链接："+msg.website;
      this.$publicFun.copyFun(str);
    },
    myCopy(msg, type) {
      let str = "";
      if (type == 4) {
        msg = msg.map(item => {
          return item ? item : "其它";
        });
        str = `媒体性质:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 1) {
        let author =
          (this.textData.source ? this.textData.source : "无") +
          "/" +
          (this.textData.author ? this.textData.author : "无");
        str = `来源/作者:${author}`;
      } else if (type == 2) {
        str = `事件分类:${msg}`;
      } else if (type == 6) {
        str = `关键字:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    heightText() {
      if (this.textData.keyword) {
        let word = this.textData.keyword.split("<br/>");
        if (word[word.length - 1] == "") {
          word.pop();
        }
        if (word.length != 0) {
          word.forEach(ite => {
            let reg = new RegExp(ite, "g"); //g代表全部
            if (this.textData.title) {
              this.textData.title = this.textData.title.replace(
                reg,
                '<span style="color:red">' + ite + "</span>"
              );
            }
            if (this.textData.contentList) {
              for (let i = 0; i < this.textData.contentList.length; i++) {
                this.textData.contentList[i] = this.textData.contentList[
                  i
                ].replace(reg, '<span style="color:red;">' + ite + "</span>");
                // font-weight:800;font-size:18px
              }
            }
          });
        }
      }
    },
    async getData(){
      this.loading = true;
      let data = {
        dataId:this.dataId,
        teamId:this.teamId
      }
      let res = await wxDataQuery(data);
      if (res.data.data != undefined) {
        this.textData = res.data.data;
        this.textData.accurateId = this.textData.accurateId + "";
        this.textData.dataId = this.textData.dataId + "";
        if (this.textData.videoPath) {
          if(this.textData.videoPath.indexOf('"type":')==-1){
            this.textData.videoPath = {
              url:this.textData.videoPath,
              type:"video/mp4"
            }
          }else{
            this.textData.videoPath = JSON.parse(this.textData.videoPath);
            if (this.textData.videoPath.type.slice(0, 5) == "image") {
              this.srcLists[0] = this.textData.videoPath.url;
            }
          }
        }
        if (this.textData.eventClass) {
          this.textData.eventList = this.textData.eventClass.split(" ");
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.eventClass = this.textData.eventClass.replace(
            reg,
            "<br/>"
          );
        } else {
          this.textData.eventList = [];
        }
        if (this.textData.keyword) {
          this.textData.keyword = this.textData.keyword.replace(/<br\/>/g," ");
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
        if (this.textData.content) {
          this.textData.contentList = this.textData.content.split("\n");
        }
        if (this.textData.fastPic) {
          this.srcList.push(this.textData.fastPic);
        }
      }
      this.heightText();
      this.loading = false;
    },
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.teamId = params.teamId;
    }
    this.getData();
  }
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.main-top-center-box {
  display: flex;
  align-items: center;
}

.main-top-center-box div {
  margin-left: 0;
  width: 58px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}

.main-top-center-box p {
  width: 94%;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}
.information-box {
  padding: 0 24px 16px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../assets/img/views/beijing.png");
}

.center-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  padding: 0 5px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  height: 24px;
}
.website {
  margin-left: 16px;
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  cursor: pointer;
  font-size: 14px;
}

.original-color {
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  display: flex;
  justify-content: space-between;
  width: 213px;
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.main-text {
  margin-top: 24px;
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.operation-box img {
  width: 16px;
  height: 16px;
}

.operation-box {
  position: relative;
  margin-top: 18px;
  display: flex;
  width: 1137px;
  font-size: 14px;
  color: #333333;
  align-items: center;
}

.operation-box > div:not(:first-child) {
  margin-left: 40px;
}

.operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.main-center {
  padding: 24px 24px 24px 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background: #f4f8fd;
}

.main-center > div {
  height: auto;
  font-size: 14px;
  color: #333333;
}

.main-center-left {
  display: flex;
  width: 89%;
  flex-wrap: wrap;
  align-items: center;
}

.event-box {
  width: 71%;
  height: 20px;
  display: flex;
  align-items: center;
}

.event-box .my-caozuo {
  margin-left: 5px;
  margin-top: 5px;
}

.left-source,
.left-language {
  display: flex;
  align-items: center;
  width: 50%;
}

.left-source .my-caozuo,
.left-language .my-caozuo {
  margin-top: 5px;
  margin-left: 5px;
}

.center-right-img {
  width: 111px;
  height: 108px;
}

.center-right-img img {
  width: 111px;
  height: 108px;
}

.main-center-right {
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}
.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}
</style>
